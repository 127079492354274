/* Site.css is generated by the Sass precompiler */
/* Please make changes to Site.scss (SASS) since changes to Site.css (CSS) will be overwritten */
@import "normalize";
@import "settings";
@import "foundation";
@import "foundation.mvc";

.foundation-data-attribute-namespace {
    font-family: false;
}

//Global Font set in _settings.scss
// $body-font-family: "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
// $primary-color: #5d9cec;
// $topbar-link-color-hover:#84bbff;
// $topbar-link-color-active:#84bbff;
// $topbar-link-hover-lightness: +20%;

$black:#333;
$MidRange:lighten($black, 50%);
$BaseBackground:#f4f4f4;

// Background color for the top bar
$topbar-bg-color:#333;
$topbar-bg:$topbar-bg-color;

html, body {height:100%; position:relative;}

#wrap {
	min-height: 100%;
	width: 100%;
}

#main {
	overflow:auto;
	margin-left: 5px;
	margin-right: 5px;
}

// Branding / Logo - Project Specific
.title-area {
	.name h1 {
		background:transparent url(../content/img/bank-bi-logo.png) no-repeat center center !important;
		background-size:90px auto !important;
		width:120px;
		text-indent:-9999em;
		overflow:hidden;
	}
}

#footer {
	position:relative;
	background:#4d4d4d;
	color:#fff;
	width:100%;
    z-index: 1;

	.shell {
		max-width:1000px;
		margin:0 auto;
		padding:0.75em 0;

		ul {
			margin-bottom:0;
			margin-left:5px;
			margin-right:5px;
			text-align:center;
		}

		li {
			font-size:0.75em;
			list-style-type:none;
			display:inline;
			font-weight:300;
			padding:0.5em;
		}

	}

	// Makes footer reformat for mobile
	@media screen and (max-width:640px) {
		
		.shell {

			li {
				display:block;
			}

		}
		
	}

}

@media #{$large-up} {
	#footer {
		position:fixed;
		bottom:0;
		left:0;

		.shell {

			ul {
				text-align:right;
			}

		}
	}

	@media screen and (max-height:600px), screen and (max-device-height:600px) {
		#footer {
			position:relative;
		}
	}
}

.top-bar {

    .name h1 a {
        background: $topbar-bg-color;
    }

    @media #{$topbar-media-query} {
        min-height: 45px;
        height: auto;
    }

    .name h1 a {
        background: $topbar-bg-color;
    }
}

.top-bar-section .dropdown {
	box-shadow:  0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.layout-with-context {

	.menu {
		/*default on mobile...*/
		@include grid-column(12);

		.side-nav {
			padding-left:9px;
			padding-right:9px;
			background:#F5FAFF;
			border:1px solid #E3E9EF;
			border-radius:3px;
			margin-bottom:15px;

			h2 {
				font-size: 1.2em;
			}

		}
		
		/*"small" here actually means that is larger than mobile. Odd.*/
		@media #{$small} {
			@include grid-column (3, false, false, false, false, 9);
		}

	}

	.content {
		/*default on mobile...*/
		@include grid-column(12);

		.body {
			padding:1em;
		}

		/*"small" here actually means that is larger than mobile. Odd.*/
		@media #{$small} {
			@include grid-column (9, false, false, false, 3, false);

			.end {
				padding-top:15px;
				display:inline-block;
				text-align:left;
			}
		}
	}
}

.inline-label {

	.field-summary {
		overflow-wrap: anywhere;
		font-size: 0.845em;
		line-height: 1.4em;
		padding-top:0;
		padding-bottom:0.55rem;
		margin-bottom:1rem;
		border-bottom:1px solid #e2e2e2;
	}

	.field-summary ul {
		margin-top:-1px;

		li {
			font-size: 0.845em;
		}
	}

}

.label-input-container {
    display: flex;

    label {
        flex: none;
        padding: 0.7rem 0;
    }

    input {
        height: 100%;
        margin-top: 0.335rem;
        margin-left: 8px;
        width: 100%;
    }
}

// Labels to left on larger screens...
@media #{$large-up} {

    .inline-label {

        label {
            text-align: right;
            margin: 0 0 0.88889rem 0;
            padding: 0.7rem 0;
        }

        .field-summary {
            padding: 0.55rem 0;
            border: 0;
        }

        a.hover-help {
            display: inline-block;
        }
    }
}

// Select2 Overrides
.select2-drop {
	font-size:14px;
}

.select2-container {
	font-size:14px;

	.select2-choice {
		border-radius:0 !important;
		height:35px !important;
		line-height:35px !important;
		background:#fff !important;
		background-image:none !important;
		border-color:#ccc !important;

		.select2-arrow {
			background:none !important;
			background-image:none !important;
			width:17px;
			border-color:#ccc !important;

			b {
				background-position:0 4px !important;
			}
		}
	}

	&.select2-dropdown-open {

		.select2-choice {
			border-color:#5897fb !important;

			.select2-arrow b {
				background-position:-17px 4px !important;
			}

		}

	}

}

.select2-results li {
	font-size:14px;
}

.select2-container-multi, .select2-container {
	margin-bottom:15px !important;
}

.select2-container-multi {

	.select2-choices {

		border-color:#ccc !important;

		.select2-search-field {

			input {
				font-size:13px !important;
				font-family: "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !important;
			}

		}

		.select2-search-choice {
			font-size:12px;
			margin-top:6px !important;
		}

	}

	&.select2-container-active {

		.select2-choices {
			border-color:#5897fb !important;
		}

	}
}

// Stop mouse pointer change on labels for text areas etc
label {
	cursor:auto;
}

// Control the formatting of radio buttons, default to inline, but can make list
.input-check-tick {
	display:block;

	label {
		cursor:pointer;
	}
}

// Darran & Squidge n-grid fixes
.gridStyle {
	border: 1px solid rgb(212,212,212);
	// width:600px;
	width: 100%;
	height: 410px;
	font-size: 0.75em;

	&.ngGrid {
		background: transparent;

		.ngHeaderCell {
			height: auto !important;
		}
	}

	.slick-grid-cell-link {
		color: $primary-color;
		cursor: pointer;
	}
}

// Fixes horizontal scrollbar appearing in Edge/Firefox
.gridStyle * {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.ngHeaderText {
	padding:8px;
}

button.ngPagerButton {
	padding:0;
	background:transparent;

	&:hover {
		background:transparent;
	}

}

.ngRowCountPicker {

	.ngLabel {
		line-height:21px;
		margin-right:10px;
	}

}

.ngFooterTotalItems {

	.ngLabel {
		line-height:27px;
	}

}

.ngCellText {
  display:table-cell;
  vertical-align:middle;
  white-space:normal;
}

input[type="number"].ngPagerCurrent {
	width:80px !important;
	text-align:center;
}

button.ngPagerButton > div {
	margin:auto;
}

input[type="number"].ngPagerCurrent {
	display:inline;
}

.ngRowCountPicker > select {
	padding:0;
	padding-left:8px;
	width:80px !important;
	cursor:pointer;
}

// jQGrid Tweaks
.ui-search-table {
	margin:10px 0;
	border:0;
	background:darken(#f4f4f4, 1%);
	border-radius:5px;

	input[type=text] {
		height:26px !important;
		text-indent:5px;
	}

	td.ui-search-input {
		padding-right:0;
	}

	.ui-search-clear {
		width:20px;
	}
}

.ui-jqgrid-htable {
	tr, td, th {
		border-color:#dddddd !important;
	}
}

.ui-search-toolbar {

	th div {
		display:block !important;
	}

}

.ui-jqgrid tr.jqgrow td {
	padding: 8px !important;
	text-align: center !important;
	border-color: #dddddd !important;
}

@media #{$large-up} {

	.list-input-check-tick {

		.input-check-tick {
			display:block;
		}

	}

	.input-check-tick {
		display:inline;
	}

}

.validation-summary-errors {
	ul {
		list-style-type: none;
		li {
			color: $alert-color;
		}
	}
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.clearfix {
	clear: both;
}


// ERRORS TOASTER
.toaster {
	position: static;
	padding: 5px;
	font-size: .75em;
	font-style: normal;

	i.icon-remove {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
		color: white;
	}

	.error-message {
		position: static;
		padding: 10px;
		background-color: $alert-color;

		li {
			border-bottom: 1px dotted #000;
			margin-left: 20px;
			margin-bottom: 5px;
		}

		span {
			font-size: medium;
		}	
	}

	.success-message {
		@extend .error-message;
		background-color: $success-color;
	}
}

.panel {

	&.callout {
		a {
			color: #2d3540;
			font-size: 0.8em;
			font-weight: normal;
			&:hover {
				color: white;
			}
		}

		&.warning {
    		background-color: #fff3d9;
		}
	}
}


// Cancel / non-background button
.button {
	font-weight:300;

	&.cancel {
		background:transparent;
		color:$primary-color;
		opacity:0.7;
		padding-left:0;
		padding-right:0;
		margin-left:2rem;
		margin-right:2rem;
		font-size:0.85em;

		&:hover {
			opacity:1;
		}

	}

	&.full {
		display:block;
		width:100%;
	}

	i {
		font-family:"WebHostingHub-Glyphs";
		font-weight:normal;
		font-size:1.4em;
		vertical-align:-18%;
		margin-right:0.5em;
	}

	&.icon-only {
		i {
			margin-right:0;
		}
	}

	@media screen and (max-width:640px) {
		width:100% !important;

		&.cancel {
			margin-left:0;
			margin-right:0;
			padding:0;
		}

	}

}

.module-form {
	h2 {
		margin-top:20px;
	}

	.sub-link {
		text-align:right;
		font-size:12px;
		
		& + .sub-link{
			margin-top: 10px;
		}
	}
}

// Button behaviour for form submitting
.bttns-right {
	text-align:right;

	@media #{$small-only} {
		text-align:center;
	}
	
}

.select-required{
	border: 1px solid #E93535;
}

.hover-help {
    cursor:help !important;
}

a.silent {
    cursor:default;
}

a.silent:hover {
    background-color:#333333 !important;
    color:white !important;
}

.scrollable-list {
    max-height: 500px;
    overflow-y: auto;
}

.status-icon {
	font-size: 20px;

	&.red {
		color: red;
	}

	&.green {
		color: green;
	}

	&.blue {
		color: #1d89cf;
	}

	&.orange {
		color: orange;
	}

    &.lightgray {
        color: lightgray;
    }
}

.reveal-modal {
	  max-height: 90%;
	  overflow: auto;
	  top: 50px;
  }

.content .condensed {
    input[type=checkbox] {
        margin: 0.3rem;
        vertical-align: middle;
    }

    label {
        margin: 0.3rem;
        padding: 0;
    }
}

.padding-bottom {
    padding-bottom: 1rem;
}

table.full-width {
    width: 100%;
    table-layout: fixed;
}

ul.error-list {
    li {
        font-size: 0.875rem;
        color: tomato;
    }
}

.reveal-modal {
	  .inline-label {
		  input {
			  width: initial;
			  display: initial;
			  height: initial;
		  }

		  label {
			  font-weight: initial;
		  }

		  input[name='delimiter'] {
			  width: 3em;
		  }

		  input[name='skipRows'] {
			  width: 4em;
		  }

		  input[name='delimiter'] + label, input[name='skipRows'] + label{
				display: inline-block;
				margin-left: 0.5rem;
				margin-right: 1rem;
				margin-bottom: 0;
				vertical-align: baseline;
		  }
	  }
  }

#formConfiguration {
	.ng-invalid-required {
		border:1px solid red;
	}

	.repeater {
		font-size: 0.75em;
		border: 1px solid $MidRange;
		border-top: 0;
		padding: 0;
		margin-bottom: 1em;
		height:500px;
		overflow-x: hidden;
		overflow-y: scroll;
		background-color: lighten($MidRange,10%);

		p {
			width: 100%;
			text-align: center;
		}

		h4.row {
			border-bottom: 1px solid $MidRange;
			margin-left: 0px;
			margin-right: 0px;
			margin-bottom: 0px;
		}

		.visible {
			.repeat-item {
				padding-top: 1em;
				padding-right: 1em;
			}
		}

		label, input, select {
			font-size: inherit;
		}

		input[type='checkbox'] {
			margin-top: 0.5rem;
		}
	}

	.repeat-item {
		.panel.callout {
			padding:1em;
		}
	}

	.repeat-item:last-of-type {
		border-bottom: 1px solid $MidRange;
		background-color: $BaseBackground;
	}

	.repeater-header {
		font-size: 0.75em;
		background-color: #eaeaea;
		margin-bottom: 0;
		overflow: hidden;
		height: auto;
		padding: 1em;
		border:1px solid $MidRange;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		line-height: 2em;

		input[type="text"] {
			width: auto;
			margin-bottom: auto;
			position: absolute;
			left: 2em;
		}

		a {
			margin-left: 1em;
		}
	}
}

fieldset.selected-csv-column {
	margin:0;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	label, select {
		margin-right:1em;
		margin-bottom:15px;
		width: initial;
		text-align: left;
	}
}

#sampleData {
	.slick-cell.Drop {
		background-color: lighten($color: #EA2F10, $amount: 25);
	}

	.slick-cell.Encrypt-Random, .slick-cell.Encrypt-Deterministic {
		background-color: lighten($color: #3C9A5F, $amount: 25);
	}
}

.upload-agent-preprocessing
{
	button, .panel {
		height: 50px;
	}

	input[type="checkbox"], input[type="text"]  {
		margin-bottom: 0;
	}

	.upload-agent-actions {
		padding:0;
		overflow-y: auto;
		max-height: 11.5em;
		display: table;
		border-collapse: collapse;
		margin-bottom: 1em;
		table-layout: fixed;
	}	

	.upload-agent-actions > div {
		display: table-row;
	}

	.upload-agent-actions > div > div:last-of-type {
		width: 4em;
		text-align: center;
	}

	.upload-agent-actions-field, .upload-agent-actions-drop, .upload-agent-actions-encrypt, .upload-agent-actions-concatenate{
		display: table-cell;
		border:1px solid #999999;
		padding-top: 0.625rem;
		padding-right: 1.25rem;
		padding-bottom: 0.6875rem;
		padding-left: 1.25rem;
		font-size: 0.6875rem;
		font-weight: bold;		
		vertical-align: middle;
	}

	.upload-agent-actions-drop, .button-drop {
		color: #ffffff;
		background-color: lighten($color: #EA2F10, $amount: 25);
	}

	.upload-agent-actions-encrypt, .button-encrypt {
		color: #ffffff;
		background-color: lighten($color: #3C9A5F, $amount: 25);
	}

	.upload-agent-actions-concatenate {
		color: #ffffff;
		background-color: lighten($color: #949A19, $amount: 25);
	}

	.upload-agent-actions-field {
		color: #000;
	}
}

.ua-sample-file-loader{
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.3);
}

.fixed-width-config{
	input{
		margin: 0px !important;
	}

	&__text-column{
		width: 15rem;
	}
	&__number-column{
		width: 6rem;
	}

	&__delete-icon{
		margin-left: 0.75rem;
		width: 1.25rem;
		height: 1.25rem;
		color: #F04124;
		cursor: pointer;
	}
}

section.code-mirror {
	.CodeMirror-wrap {
		margin-bottom: 1em;
	}
}

.config-table-language{
	display:inline-flex;
	padding: 0 15px 0 15px;
	justify-content: center;
	align-items: center;

	label {
		margin: 0 10px 0 0;
		padding: 0;
	}

	select{
		margin: 0;
	}
}

.iti { width: 100%; }

.red-border{
	border-color: red !important;
}

.select-all-checkbox-text{
    color: #BBB;
    font-style: italic;
}